import React from "react";
import { SubmitIcon } from "../../services/utilities";
import { Sidebar, Menu, SubMenu } from "react-pro-sidebar";
import Box from "@mui/material/Box";
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import { useInfiniteHits } from "react-instantsearch";
import { DataGrid } from "@mui/x-data-grid";

import {
  ClearFilters,
  NoResults,
  NoResultsBoundary,
  Panel,
  CustomStats,
} from "../shared";
import algoliasearch from "algoliasearch";
import {
  CurrentRefinements,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  Configure,
  useInstantSearch,
} from "react-instantsearch";

defineCustomElements(window);
const searchClient = algoliasearch(
  "ETT8OD4DUG",
  "bb501e3aa5c51ecc4515f46406dc894c"
);
const indexName = "PROD_HIGH_SCHOOLS";

function ExportButton() {
  const { scopedResults } = useInstantSearch();

  const handleExport = async () => {
    let totalHits = [];
    console.log("Scoped Results:", scopedResults);
    console.log("Scoped Results:", scopedResults[0].results?._rawResults[0]);

    if (scopedResults[0].results?._rawResults[0]?.nbHits > 0) {
      console.log("Exporting Records...");
      const results = scopedResults[0].results?._rawResults[0];
      const query = results?.query;
      const filters = new URLSearchParams(results.params);
      const facetFilters = filters.get("facetFilters");

      let browseIndex = searchClient.initIndex(indexName);
      await browseIndex.browseObjects({
        query: query || "",
        facetFilters: facetFilters,
        batch: (batch) => {
          batch.forEach((hit) => {
            totalHits.push(hit);
          });
        },
      });

      console.log("Total Hits:", totalHits.length);

      const data = await convertData(totalHits);

      console.log("Data:", data.length);

      // Trigger CSV download
      const csvLink = document.createElement("a");
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      csvLink.href = url;
      csvLink.setAttribute("download", "highschools.csv");
      document.body.appendChild(csvLink);
      csvLink.click();
      document.body.removeChild(csvLink);
    }
  };

  return (
    <button onClick={handleExport} className="btn btn-success">
      Export Records →
    </button>
  );
}

function getRowId(row) {
  return row.objectID;
}

function convertData(input) {
  console.log("Converting Data...", input.length);
  const csvRows = [];

  const headers = [
    "ETS",
    "School",
    "Address",
    "City",
    "State",
    "Zip",
    "County",
    "Coach",
    "Phone",
    "Cell",
    "Email",
    "Twitter",
  ];
  
  const escapeValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      const escapedValue = value.replace(/"/g, '""');

      // Wrap in quotes if it contains commas, double quotes, or line breaks
      if (
        escapedValue.includes(",") ||
        escapedValue.includes('"') ||
        /\r?\n/.test(escapedValue)
      ) {
        return `"${escapedValue}"`;
      }

      return escapedValue; // Return the value as-is if no special characters
    }
    return value;
  };

  input.forEach((school) => {
    const values = [
      escapeValue(school.ETS),
      escapeValue(school.School),
      escapeValue(school.Address),
      escapeValue(school.City),
      escapeValue(school.State),
      escapeValue(school.Zip),
      escapeValue(school.County),
      escapeValue(school.Coach),
      escapeValue(school.Phone),
      escapeValue(school.Cell),
      escapeValue(school.Email),
      escapeValue(school.Twitter),
    ];
    csvRows.push(values.join(","));
  });

  return [headers.map(escapeValue).join(","), ...csvRows].join("\n");
  
}

export function CustomDataGrid(props) {
  const { hits: currentPageHits } = useInfiniteHits(props);
  const columns = [
    { field: "id", headerName: "Id", flex: 1, sortable: true },
    { field: "School", headerName: "Name", flex: 4, sortable: true },
    { field: "ETS", headerName: "ETS", flex: 2, sortable: true },
    {
      field: "Address",
      headerName: "Address",
      flex: 4,
      sortable: true,
      renderCell: (params) => (
        <div>
          {params.row.Address}
          <br />
          {params.row.City}, {params.row.State} {params.row.Zip}
        </div>
      ),
    },
    { field: "State", headerName: "State", flex: 1, sortable: true },
    { field: "County", headerName: "County", flex: 2, sortable: true },
    {
      field: "Coach",
      headerName: "Coach",
      flex: 4,
      sortable: true,
      renderCell: (params) => (
        <div>
          {params.row.Coach ? params.row.Coach : "No Coach Assigned"}
          <br />
          {params.row.Phone ? params.row.Phone : "No Phone Provided"}
          <br />
          {params.row.Cell ? params.row.Cell : "No Cell Provided"}
          <br />
          {params.row.Email ? (
            <a href={`mailto:${params.row.Email}`}>{params.row.Email}</a>
          ) : (
            "No Email Provided"
          )}
          <a href={`https://twitter.com/${params.row.Twitter}`}>
            {params.row.Twitter}
          </a>
        </div>
      ),
    },
  ];

  return (
    <div>
      {currentPageHits && currentPageHits.length > 0 ? (
        <div style={{ overflowX: "auto" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                transform: "rotateX(180deg)",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                transform: "rotateX(180deg)",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              "& .MuiDataGrid-columnHeaders": {
                color: "white",
                backgroundColor: "#026534",
                fontWeight: "bold",
                position: "sticky",
                top: 0,
                zIndex: 1, // Ensure the header stays above other content
              },
            }}
            rows={currentPageHits}
            density={"comfortable"}
            rowHeight={80}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            getRowId={getRowId} // Pass the function reference
          />
        </div>
      ) : null}
    </div>
  );
}

export default function HighSchools(props) {
  const { role } = props;

  return (
    <div className="row" style={{ marginRight: "0", marginLeft: "0" }}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        insights={true}
      >
        <Configure analytics={false} hitsPerPage={1000} />

        <div
          className="col-md-3 col-xs-12 d-flex justify-content-center custom-scrollbar"
          style={{
            height: "100vh",
            overflowY: "scroll",
            backgroundColor: "#222222",
          }}
        >
          <div className="filter-sidebar">
            <h3 style={{ width: "100%", textAlign: "center" }}>High Schools</h3>
            <hr />
            <section className="container-filters pt-3">
              <div className="container-body">
                <SearchBox
                  placeholder="Search ... "
                  submitIconComponent={SubmitIcon}
                />
                <CustomStats />
                <Sidebar>
                  <Menu
                    menuItemStyles={{
                      button: {
                        border: "none",
                        minWidth: "0px",
                        marginLeft: "0",
                        paddingLeft: "0",
                        [`&.active`]: {
                          backgroundColor: "#222222",
                          color: "#7AFD78",
                        },
                        [`&:hover`]: {
                          backgroundColor: "#222222",
                          color: "#7AFD78 !important",
                        },
                      },
                    }}
                  >
                    <Panel header="State">
                      <RefinementList
                        attribute="State"
                        limit="10"
                        searchable={true}
                      />
                    </Panel>
                    <hr />
                    <Panel header="County">
                      <RefinementList
                        attribute="County"
                        limit="10"
                        searchable={true}
                      />
                    </Panel>
                    <hr />

                    <Panel header="High School">
                      <RefinementList attribute="School" searchable={true} />
                    </Panel>
                    <hr />
                  </Menu>
                </Sidebar>
              </div>
            </section>
          </div>
        </div>
        <div
          className="col-md-9 col-xs-12"
          style={{
            height: "100vh",
            overflowY: "scroll",
            backgroundColor: "#F3F3F3",
          }}
        >
          <div className="box-content">
            <main className="container">
              <section className="container-results col-md-12">
                <header className="container-header container-options">
                  <div className="col-md-9">
                    <ClearFilters />
                    <CurrentRefinements excludedAttributes={["date"]} />
                  </div>
                  <div className="col-md-3" style={{ textAlign: "right" }}>
                    <ExportButton />
                  </div>
                </header>
                <div className="">
                  <NoResultsBoundary fallback={<NoResults />}>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <CustomDataGrid />
                    </Box>
                  </NoResultsBoundary>
                  <footer className="container-footer">
                    <Pagination padding={2} showFirst={true} showLast={true} />
                  </footer>
                </div>
              </section>
            </main>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}
